.App {
  text-align: center;
}
body {
  background: url(bg.png) !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center center !important;
}
.gif__container {
  width: 70%;
  margin-left: 15%;
}
.gif {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: auto;
  margin-bottom: 3vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.651) 0px 15px 25px;
}
.logo__image {
  width: 16%;
  margin-left: 42%;
  margin-right: 42%;
  height: auto;
  margin-bottom: 3vh;
}
